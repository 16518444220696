module.exports = {
  DEV: {
    newrelicApplicationId: '1120313402',
    newrelicLicenseKey: 'NRJS-c79c0b8f95200184ea4',
    newrelicAccountId: '4029335',
    newrelicTrustKey: '4139912',
  },
  UAT: {
    newrelicApplicationId: '1120313452',
    newrelicLicenseKey: 'NRJS-c79c0b8f95200184ea4',
    newrelicAccountId: '4029335',
    newrelicTrustKey: '4139912',
  },
  PRD: {
    newrelicApplicationId: '1120313454',
    newrelicLicenseKey: 'NRJS-c79c0b8f95200184ea4',
    newrelicAccountId: '4029335',
    newrelicTrustKey: '4139912',
  },
};