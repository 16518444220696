import styled from 'styled-components';

export const ContainerHeader = styled.header`
@media print {
  display: none;
}
  z-index: 1071;
  .test {
    display: flex;
    justify-content: center;
  }
  .main-row {
    max-width: 1110px;
    width: 100%;
  }
  background-color: #ffffff;
  button {
    :focus {
      outline: none !important;
    }
  }
  .search-input {
    position: fixed;
    z-index: 12;
    background-color: #f5f5f5;
    right: 0;
    left: 0;
    height: 76px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid #e31c39;
    svg {
      font-size: 38px;
      margin: 0 10px;
      cursor: pointer;
      &.search {
        color: #949494;
        font-size: 32px;
      }
    }
    .suggestions {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      left: 0;
      width: 100%;
      top: 74px;
      background-color: #f5f5f5;
      list-style: none;
      color: #495057;
      font-size: 16px;
      padding: 0 20px 20px 20px;
      max-height: 180px;
      overflow: hidden;
      border-bottom: 2px solid #e31c39;
      li {
        max-width: 1070px;
        width: 100%;
        margin-bottom: 6px;
        cursor: pointer;
      }
    }
    input {
      background-color: transparent;
      border: 0;
      max-width: 1070px;
      width: 100%;
      color: #495057;
      height: 74px;
      font-size: 24px;
      line-height: 36px;
      font-weight: 700;
      :focus {
        outline: none;
      }
      ::-ms-clear {
        display: none;
      }
    }
  }
  &.header-fixed {
    animation: smoothScroll 0.5s forwards;
    transition: all 0.5s ease;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid #e31c39;
    z-index: 1071;
    .top-menu-section {
      height: auto;
      justify-content: flex-end;
      .logo {
        position: absolute;
        left: 15px;
        z-index: 9;
      }
      .top-menu-nav {
        margin-top: 15px;
        .nav-list {
          padding: 0 8px;
          .item-0 {
            &:hover {
              color: #e31c39;
            }
          }
        }
        .search-item {
          padding: 0 8px;
          &:hover {
            color: #e31c39;
          }
        }
      }
      a,button {
        :hover {
          text-decoration: underline;
        }
      }
    }
    .menu-section {
      background-color: transparent;
      justify-content: flex-end;
      .home {
        display: none;
      }
      .nav-list {
        .item-0 {
          color: #544f40;
          margin-right: -6px;
          &:hover,
          &.active {
            color: #e31c39;
          }
        }
        a,button {
          :hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .row {
    display: flex;
    justify-content: center;
  }
  .top-menu-section {
    max-width: 1110px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    height: 75px;
    .logo {
      max-width: 50px;
      width: 100%;
      padding: 15px 0;
    }
    .top-menu-nav {
      display: flex;
      .mobile-menu {
        display: none;
      }
      .search-item {
        padding-left: 16px;
        border: 0;
        border-left: 1px solid #d5d1ce;
        background-color: transparent;
        color: #544f40;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: center;
        img {
          height: 15px;
          width: 15px;
          margin-right: 6px;
        }
        &:focus {
          .search-item-content {
            border: 2px solid black;
          }
        }
        .search-item-content {
          display: flex;
          align-items: center;
        }
      }
      .nav-list {
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: 1px solid #d5d1ce;
        padding: 0 16px;
        .item-0 {
          color: #544f40;
          font-size: 14px;
          line-height: 21px;
          display: flex;
          align-items: center;
          img {
            height: 15px;
            width: 15px;
            margin-right: 6px;
          }
          :hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .menu-section,
  .menu-collapse {
    max-width: 1110px;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #e31c39;
    .home {
      padding: 8px 9px;
      margin: 2px 2px 2px -13px;
      .home-icon {
        display: block;
        height: 24px;
        width: 22px;
        background: url(/icons/home-white.png) no-repeat center center;
        background-size: contain;
      }
      @media (hover: hover) {
        :hover {
          background-color: #ffffff;
          .home-icon {
            background-color: white;
            background: url(/icons/home-pink.png) no-repeat center center;
            background-size: contain;
          }
        }
      }
    }
    .nav-list {
      height: 100%;
      padding: 2px;
      display: flex;
      a {
        color: #ffffff;
        font-size: 16px;
        display: flex;
        align-items: center;
        :hover,
        &.active {
          color: #e31c39;
          background-color: #ffffff;
          text-decoration: none;
        }
        :hover {
          text-decoration: underline;
        }
        span {
          padding: 8px 9px;
        }
      }
    }
  }
  @media (min-width: 320px) and (max-width: 769px) {
    &.header-fixed {
      .top-menu-section {
        padding-bottom: 15px;
      }
      .menu-collapse {
        .home {
          display: none;
        }
      }
    }
    .top-menu-section {
      height: 63px;
      border-bottom: solid 1px #d5d1ce;
      .logo {
        padding-left: 10px;
      }
      .top-menu-nav {
        margin: 4px 0;
        .nav-list {
          border: 0;
          font-size: 14px;
        }
        .nav-list {
          padding-right: 15px;
        }
        .search-item {
          border-right: 1px solid #d5d1ce;
          padding-right: 15px;
          padding-right: 15px;
          font-size: 14px;
        }
        .mobile-menu {
          padding-left: 15px;
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #e31c39;
          border-color: transparent;
          background-color: transparent;
          svg {
            margin-left: 12px;
            height: 18px;
            width: 18px;
          }
        }
      }
    }
    .menu-section {
      display: none;
    }
    .menu-collapse {
      .nav-list {
        margin-left: 20px;
      }
      .home-icon {
        margin-left: 30px;
      }
    }
  }
  @media (min-width: 320px) and (max-width: 480px) {
    &.header-fixed {
      display: none;
      animation: none;
      transition: none;
    }
    .search-input {
      svg {
        font-size: 38px !important;
      }
    }
    .top-menu-nav {
      .nav-list {
        span {
          display: none;
        }
      }
      .search-item {
        p {
          display: none;
        }
      }
    }
  }
`;
