import styled from 'styled-components';
import { pinkFont } from '../../constants/theme';

export const BactTop = styled.div`
@media print { 
  display: none;
}
  div {
    bottom: 266px !important;
    right: 2% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 5;
    .up-arrow {
      width: 40px;
      height: 40px;
      background: url(/icons/icon_arrowtop-h.png) no-repeat;
      display: block;
      margin-bottom: 1px;
    }
  }
  span {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #4a4a4a;
  }

  &:hover {
    span {
      color: ${pinkFont};
    }
    .up-arrow{
      background: url(/icons/icon_arrowtop.png) no-repeat;
    }
  }

  //TABLET
  @media (min-width: 768px) and (max-width: 1200px) {
    div{
      right: 1% !important;
      bottom: 170px !important;
    }
  }

  //MOBILE
  @media (max-width: 767px) {
    span {
      display: none;
    }
    div {
      bottom: 5% !important;
      right: 5% !important;
    }
  }
`;
