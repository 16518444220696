import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { BactTop } from './back-to-top.css';

const BackToTop = () => {
  return (
    <BactTop>
      <ScrollToTop showUnder={200}>
        <i className={'up-arrow'}></i>
        <span>{'Back to Top'}</span>
      </ScrollToTop>
    </BactTop>
  );
};

export default BackToTop;

