import styled from 'styled-components';
import { pinkFont } from '../../constants/theme';

export const Container = styled.footer`
  line-height: 1.5;
  display: flex;
  justify-content: center;
  background-color: white;
  padding: 0;
  background-color: white;
  max-width: 1140px;
  padding: 0 15px;
  margin: 0 auto;
  justify-content: center;
  .rights {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    p {
      font-size: 12px;
      line-height: 18px;
      color: #212529;
      a {
        color: #919194;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
          color: ${pinkFont};
        }
        &:active {
          text-decoration: underline;
          color: ${pinkFont};
        }
      }
    }
  }
  .footer-content {
    border-top: solid 1px #59595b;
    max-width: 1140px;
    height: 100%;
    width: 100%;
    padding-top: 36px;
    .row {
      margin-bottom: 10px;
    }
  }
  .icon-section {
    a {
      padding: 0;
    }
  }
  .social-section {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .icon {
      font-size: 28px;
      margin: 0 12px;
    }
    span {
      color: #212529;
      font-size: 16px;
    }
    a {
      color: rgba(74, 74, 74, 0.7);
      text-decoration: none;
      line-height: 0;
      &:hover {
        color: #e31c39;
      }
    }
  }
  .logo-img {
    max-width: 66px;
  }
  .nav-section {
    padding: 0;
    a {
      :hover {
        text-decoration: underline;
      }
    }
  }
  .powered-section {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: solid 1px #59595b;
    padding-top: 8px;
    p {
      margin-right: 8px;
      font-size: 12px;
    }
    img {
      max-height: 56px;
    }
  }

  //TABLET & MOBILE
  @media (min-width: 320px) and (max-width: 1024px) {
    .social-section {
      justify-content: center;
      margin-bottom: 15px;
    }
    .rights {
      text-align: center;
    }
  }

  //TABLET
  @media (min-width: 768px) and (max-width: 1024px) {
    max-width: 720px;
  }

  @media (min-width: 992px) and (max-width: 1024px) {
    max-width: 960px;
  }
  //MOBILE
  @media (max-width: 36em) {
    .social-section {
      .icon {
        margin-left: 5px;
        margin-right: 0;
      }
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    max-width: 540px;
  }
`;

export const NavSection = styled.div`
  width: 100%;
  display: flex;
  a {
    color: #1f1f1f;
    &:hover {
      color: #e31c39;
      text-decoration: none;
    }
  }
  .nav-link {
    padding: 0;
    text-decoration: none;
  }
  .nav-list {
    margin: 0 0 0.1px;
    a {
      display: inline-block;
      border-right: 1px dotted #919194;
      padding: 0 10px;
      font-size: 14px;
      line-height: 24px;
    }
  }

  //TABLET
  @media (min-width: 768px) and (max-width: 1024px) {
    justify-content: center;
    margin: 6px 0;
    .nav-list {
      margin-bottom: 10px;
    }
  }

  //MOBILE
  @media (min-width: 320px) and (max-width: 767px) {
    margin: 6px 0;
    .nav-list {
      width: 100%;
      margin-bottom: 10px;
      a {
        display: flex;
        justify-content: center;
        border: none;
      }
    }
  }
`;
