/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';

const Autocomplete = ({
  suggestions=[],
  placeholder,
  name,
  changeValue,
  keyDownCallback,
  reset,
  userInput,
}) => {
  // The active selection's index
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  // The suggestions that match the user's input
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  // Whether or not the suggestion list is shown
  const [showSuggestions, setShowSuggestions] = useState(true);
  // What the user has entered
  const [internalUserInput, setInternalUserInput] = useState('');

  // Event fired when the input value is changed
  const onChange = e => {
    const userInput = e.currentTarget.value;

    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = suggestions.filter(
      suggestion =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );
    // Update the user input and filtered suggestions, reset the active
    // suggestion and make sure the suggestions are shown
    changeValue(e.currentTarget.value);
    setActiveSuggestion(0);
    setFilteredSuggestions(filteredSuggestions);
    setShowSuggestions(true);
    setInternalUserInput(e.currentTarget.value);
  };

  // Event fired when the user clicks on a suggestion
  const onClick = e => {
    // Update the user input and reset the rest of the state
    changeValue(e.currentTarget.innerText);
    setActiveSuggestion(0);
    setFilteredSuggestions([]);
    setShowSuggestions(false);
    setInternalUserInput(e.currentTarget.innerText);
  };

  useEffect(() => {
    if (userInput) {
      setInternalUserInput(userInput);
    }
  }, []);

  useEffect(() => {
    if (reset === true && internalUserInput !== '') {
      setInternalUserInput('');
    }
  }, [reset, internalUserInput]);

  // Event fired when the user presses a key down
  const onKeyDown = e => {
    // User pressed the enter key, update the input and close the
    // suggestions
    if (e.keyCode === 13) {
      setActiveSuggestion(0);
      setShowSuggestions(false);
      setFilteredSuggestions([]);
    }
    // User pressed the up arrow, decrement the index
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      setActiveSuggestion(activeSuggestion - 1);
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      setActiveSuggestion(activeSuggestion + 1);
    }
    keyDownCallback && keyDownCallback(e);
  };

  let suggestionsListComponent;

  if (showSuggestions && userInput) {
    if (filteredSuggestions.length) {
      suggestionsListComponent = (
        <ul className="suggestions">
          {filteredSuggestions.map((suggestion, index) => {
            let className;
            if (index === activeSuggestion) {
              className = 'suggestion-active';
            }
            return (
              <li className={className} key={suggestion} onClick={onClick}>
                {suggestion}
              </li>
            );
          })}
        </ul>
      );
    }
  }

  return (
    <Fragment>
      <input
        autoComplete="off"
        id={name}
        type="text"
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={userInput}
        placeholder={placeholder}
        aria-label={`label ${placeholder}`}
        maxLength="100"
        onBlur={() =>
          setTimeout(() => {
            setShowSuggestions(false);
          }, 200)
        }
      />
      {suggestionsListComponent}
    </Fragment>
  );
};

Autocomplete.propTypes = {
  suggestions: PropTypes.instanceOf(Array),
  placeholder: PropTypes.string,
  name: PropTypes.string,
  changeValue: PropTypes.func,
  keyDownCallback: PropTypes.func,
  reset: PropTypes.bool,
  userInput: PropTypes.string,
};

export default Autocomplete;
