import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Container, NavSection } from './footer.css';
import Nav from '../nav/nav';
import { Row, Col, NavLink } from 'reactstrap';
import Img from 'gatsby-image';
import {
  FaFacebookF,
  FaTwitter,
  FaYoutube,
  FaLinkedinIn,
} from 'react-icons/fa';

const FooterH = ({
  data,
  selectedLanguage,
  facebook,
  twitter,
  youtube,
  linkedin,
  informaUrl,
  description,
  upper,
  footerMenu,
}) => {
  const socialSection = () => {
    return (
      <div className={'social-section'}>
        <span>{'Connect with us'}</span>
        <a
          href={facebook}
          target="_blank"
          rel="noopener noreferrer"
          className={'icon'}
          aria-label={'facebook'}
        >
          <FaFacebookF />
        </a>
        <a
          href={twitter}
          target="_blank"
          rel="noopener noreferrer"
          className={'icon'}
          aria-label={'twitter'}
        >
          <FaTwitter />
        </a>
        <a
          href={youtube}
          target="_blank"
          rel="noopener noreferrer"
          className={'icon'}
          aria-label={'youtube'}
        >
          <FaYoutube />
        </a>

        <a
          href={linkedin}
          target="_blank"
          rel="noopener noreferrer"
          className={'icon'}
          aria-label={'linkedin'}
        >
          <FaLinkedinIn />
        </a>
      </div>
    );
  };
  return (
    <Container className={upper ? 'special' : ''}>
      <div className={'footer-content'}>
        <Row>
          <Col
            className={'icon-section'}
            xl={{ size: 1, order: 1 }}
            lg={12}
            md={12}
            sm={12}
          >
            <NavLink className={'logo-img'} href={`/${selectedLanguage}/`}>
              <Img
                fluid={data.headerJson.logo.childImageSharp.fluid}
                alt={'Viiv'}
              />
            </NavLink>
          </Col>
          <Col xl={{ size: 4, order: 3 }} lg={12} md={12} sm={12}>
            {socialSection()}
          </Col>

          <Col
            className={'nav-section'}
            xl={{ size: 7, order: 2 }}
            lg={12}
            md={12}
            sm={12}
          >
            <NavSection>
              <Nav navItems={footerMenu} selectedLanguage={selectedLanguage} />
            </NavSection>
          </Col>
          <Col
            className={'rights'}
            xs={12}
            xl={{ size: 10, offset: 1, order: 4 }}
          >
            <p>{`©${new Date().getFullYear()} ${description}`}</p>
          </Col>
          <Col xs={12} xl={{ order: 5 }}>
            <div className="powered-section">
              <p>{'Powered by'}</p>
              <a
                href={informaUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex"
              >
                <img
                  src="/images/citeline_logo.svg"
                  alt="Citeline Logo"
                />
              </a>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

const FooterWithQuery = (props) => (
  <StaticQuery
    query={graphql`
      query {
        headerJson {
          logo {
            childImageSharp {
              fluid(maxWidth: 66, quality: 90) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          title
        }
      }
    `}
    render={(data) => <FooterH data={data} {...props} />}
  />
);

export default FooterWithQuery;

FooterH.propTypes = {
  data: PropTypes.object.isRequired,
  footerMenu: PropTypes.array.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
  title: PropTypes.string,
  facebook: PropTypes.string.isRequired,
  twitter: PropTypes.string.isRequired,
  youtube: PropTypes.string.isRequired,
  linkedin: PropTypes.string.isRequired,
  informaUrl: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  upper: PropTypes.bool,
};
