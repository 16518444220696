import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { StaticQuery, graphql } from 'gatsby';
import { Location } from '@reach/router';

const SiteNav = ({ navItems, selectedLanguage, icon, data }) => {
  let items = data.pageMetadataJson.pages;
  let currentItems = items
    .filter(page => {
      return page.pageLang === selectedLanguage && navItems.includes(page.url);
    })
    .sort((a, b) => {
      items.indexOf(a.url) - items.indexOf(b.url);
    });

  return (
    <div className={'nav-list'}>
      <Location>
        {({ location }) => {
          const onlyActives = location.pathname.split('/').slice(2, 3);
          return currentItems.map((item, index) =>
            item.url.includes('http') ? (
              <a
                key={index}
                href={`${item.url}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.menuTitle}
              </a>
            ) : (
              <Link
                key={index}
                className={
                  `item-${index}` +
                  (onlyActives.includes(item.url) ? ' active' : '')
                }
                to={`/${selectedLanguage}` + '/' + `${item.url}`}
              >
                {icon ? <img src={icon} alt={'test'} /> : null}
                <span>{item.menuTitle}</span>
              </Link>
            )
          );
        }}
      </Location>
    </div>
  );
};

SiteNav.propTypes = {
  data: PropTypes.object.isRequired,
  selectedLanguage: PropTypes.any,
  navItems: PropTypes.any,
  icon: PropTypes.string,
};

const NavQuery = props => (
  <StaticQuery
    query={graphql`
      query {
        pageMetadataJson {
          pages {
            menuTitle
            url
            pageLang
          }
        }
      }
    `}
    render={data => <SiteNav data={data} {...props} />}
  />
);

export default NavQuery;
