import styled, { createGlobalStyle } from 'styled-components';
import {
  white,
  pinkFont,
  fontColor,
  blueFont,
  whiteStatusIcons,
  statusIcons,
  studyOverviewIcons,
} from './constants/theme';
import { UncontrolledTooltip } from 'reactstrap';

export default createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  html {
    font-size: 62.5%;
  }

  html, body {
    font-family: "Muli", sans-serif;
    font-weight: normal;
    position: relative;
  }
  .website-container {
    min-height: 50vh;
    justify-content: center;
    display: flex;
  }
  .grecaptcha-badge {
    visibility: hidden; 
  }
  [data-reach-dialog-overlay] {
    overflow: scroll;
    z-index: 8
  }
  @media (min-width: 320px) and (max-width: 767px) {
    .details-page-mobile {
      padding: 0 20px;
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    .details-page-mobile {
      padding: 0 20px;
    }
  }
  header button{
    :hover {
      text-decoration: underline;
    }
  }
  header .main-row .top-menu-section .top-menu-nav a {
    :hover {
      text-decoration: underline;
    }
  }

  .osano-cm-dialog {
    .close-button {
      position: absolute;
      top: 0;
      right: 6px;
      cursor: pointer;
      font-size: 24px;
    }
    .osano-cm-dialog__close{
      display:none;
    }
  }
  .osano-cm-content__message {
    max-height: 110px;
    visibility: hidden;
    font-size: 0;
    &::before {
      content: 'ViiV Healthcare uses cookies to improve your experience on this website. You may block the use of cookies, however, some are essential for the website to operate properly. Please select “Accept All" to confirm that you have read and accept the terms of our ';
      visibility: visible;
      font-size: 16px;
    }
  }
  .osano-cm-info-dialog__info {
    .osano-cm-header {
      visibility: hidden;
      font-size: 0;
      &::before {
        content: "Your Privacy";
        visibility: visible;
        font-size: 16px;
        padding: 16px 12px 0;
      }
    }
    .osano-cm-info__info-views {
      .osano-cm-info-views__view {
        .osano-cm-description {
          &:nth-child(1) {
            visibility: hidden;
            font-size: 0;
            &::before {
              font-size: 12px;
              visibility: visible;
              content:"When you visit any web site, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to. The information does not usually directly identify you, but it can give you a more personalised web experience. Because we respect your right to privacy, you can choose not to allow some types of cookies. Click on the different category headings to find out more and change our default settings. However, blocking some types of cookies may impact your experience of the site and the services we are able to offer."
            }
          }
        }
        .osano-cm-view__list {
          li {
            &:nth-child(1) {
              label {
                .osano-cm-toggle__label {
                  visibility: hidden;
                  font-size: 0;
                  &::before {
                    font-size: 14px;
                    visibility: visible;
                    content: "Strictly Necessary";
                  }
                }
              }
              .osano-cm-list-item__description {
                p {
                  visibility: hidden;
                  font-size: 0;
                  &::before {
                    font-size: 12px;
                    visibility: visible;
                    content: "These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work. These cookies do not store any personally identifiable information.";
                  }
                }
              }
            }
            &:nth-child(2) {
              label {
                .osano-cm-toggle__label {
                  visibility: hidden;
                  font-size: 0;
                  &::before {
                    font-size: 14px;
                    visibility: visible;
                    content: "Targeting Cookies";
                  }
                }
              }
              .osano-cm-list-item__description {
                p {
                  visibility: hidden;
                  font-size: 0;
                  &::before {
                    font-size: 12px;
                    visibility: visible;
                    content: "These cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites. They do not store directly personal information, but are based on uniquely identifying your browser and internet device. If you do not allow these cookies, you will experience less targeted advertising.";
                  }
                }
              }
            }
            &:nth-child(3) {
              label {
                .osano-cm-toggle__label {
                  visibility: hidden;
                  font-size: 0;
                  &::before {
                    font-size: 14px;
                    visibility: visible;
                    content: "Functional Cookies";
                  }
                }
              }
              .osano-cm-list-item__description {
                p {
                  visibility: hidden;
                  font-size: 0;
                  &::before {
                    font-size: 12px;
                    visibility: visible;
                    content: "These cookies enable the website to provide enhanced functionality and personalisation. They may be set by us or by third party providers whose services we have added to our pages. If you do not allow these cookies then some or all of these services may not function properly.";
                  }
                }
              }
            }
            &:nth-child(4) {
              label {
                .osano-cm-toggle__label {
                  visibility: hidden;
                  font-size: 0;
                  &::before {
                    font-size: 14px;
                    visibility: visible;
                    content: "Performance Cookies";
                  }
                }
              }
              .osano-cm-list-item__description {
                p {
                  visibility: hidden;
                  font-size: 0;
                  &::before {
                    font-size: 12px;
                    visibility: visible;
                    content: "These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not know when you have visited our site, and will not be able to monitor its performance.";
                  }
                }
              }
            }
            &:nth-child(5) {
              display: none;
            }
          }
        }
      }
    }
  }
`;

export const Fonts = styled.div`
  h1,
  h2 {
    font-size: 40px;
    font-weight: 400;
    line-height: 1.2;
    color: ${pinkFont};
  }
  h3 {
    font-size: 30px;
    font-weight: 400;
    line-height: 1.2;
    color: ${pinkFont};
  }
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: ${fontColor};
  }
  strong {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    color: ${fontColor};
  }
  a {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: ${fontColor};
    :hover {
      text-decoration: none;
    }
  }
  @media screen and (max-width: 767px) {
    h2 {
      font-size: 26px;
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  padding: 0 15px;
  margin: 0;
  @media screen and (min-width: 576px) {
    max-width: 540px;
  }
  @media screen and (min-width: 768px) {
    max-width: 720px;
  }
  @media screen and (min-width: 992px) {
    max-width: 960px;
  }
  @media screen and (min-width: 1200px) {
    max-width: 1140px;
  }
`;

export const StudyRegister = styled.div`
  margin-top: 15px;
  padding: 0 15px;
  a,
  button {
    :hover {
      text-decoration: underline;
    }
  }
  p {
    b {
      font-weight: bold;
    }
    margin-bottom: 16px;
    &.img-c {
      width: 60%;
      float: right;
      img {
        max-width: 100%;
        width: 100%;
      }
    }
    a {
      color: ${pinkFont};
      &:hover {
        color: #007bff !important;
      }
      &:visited {
        color: ${pinkFont};
      }
    }
  }
  h2 {
    margin-bottom: 8px;
  }
  .container {
    padding: 0 15px;
  }

  //TABLET AND MOBILE
  @media (max-width: 768px) {
    p {
      text-align: justify;
      &.img-c {
        width: 100%;
      }
    }
  }
`;

export const LaypersonStudyResult = styled.div`
  margin-top: 15px;
  padding: 0 15px;
  a,
  button {
    :hover {
      text-decoration: underline;
    }
  }
  p {
    b {
      font-weight: bold;
    }
    margin-bottom: 16px;
    &.img-c {
      width: 60%;
      float: right;
      img {
        max-width: 100%;
        width: 100%;
      }
    }
    a {
      color: ${pinkFont};
      &:hover {
        color: #007bff !important;
      }
      &:visited {
        color: ${pinkFont};
      }
    }
  }
  h2 {
    margin-bottom: 8px;
  }
  .container {
    padding: 0 15px;
  }

  //TABLET AND MOBILE
  @media (max-width: 768px) {
    p {
      text-align: justify;
      &.img-c {
        width: 100%;
      }
    }
  }
`;

export const HealthcarePartners = styled.div`
  padding: 0 15px;
  a,
  button {
    :hover {
      text-decoration: underline;
    }
  }
  p {
    margin-bottom: 16px;
  }

  h2 {
    margin-bottom: 8px;
  }
  a {
    color: ${pinkFont};
    &:hover {
      color: #007bff !important;
    }
    &:visited {
      color: ${pinkFont};
    }
  }

  //TABLET AND MOBILE
  @media (max-width: 768px) {
    p {
      text-align: justify;
    }
  }
`;

export const ContactPageContainer = styled.div`
  h1 {
    font-size: 30px;
    line-height: 1.2;
    padding: 16px 16px 25px 16px;
    border-bottom: 1px solid #e9ecef;
  }
  .error-list {
    background-color: #fef5f1;
    margin-top: 15px;
    ul {
      color: #8c2e0b;
      background: url(/icons/message-24-error.png) no-repeat 8px 8px;
      border: 1px solid #ed541d;
      padding: 10px 10px 10px 50px;
      margin: 6px 0;
      li {
        list-style-type: disc;
        font-size: 16px;
        line-height: 1.5;
        margin-left: 16px;
      }
    }
  }
  .msg-sended {
    color: #234600;
    background: url(/icons/message-24-ok.png) no-repeat 8px 8px;
    border: 1px solid #be7;
    background-color: #f8fff0;
    padding: 10px 10px 10px 50px;
    margin: 15px 0 6px 0;
    p {
      margin: 0;
    }
  }
`;

export const ResultsTable = styled.div`
  thead {
    tr {
      th {
        :hover {
          text-decoration: underline;
        }
        background-color: #e31c39;
        color: #fff;
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        padding: 10px 8px;
        white-space: nowrap;
        cursor: pointer;
        &.location {
          padding: 10px 15px;
        }
        &.conditions {
          cursor: default;
          &:hover {
            text-decoration: none;
          }
        }
        img,
        svg {
          height: 13px;
          width: 13px;
          margin-left: 4px;
          font-size: 15px;
          font-weight: 700;
        }
      }
    }
  }
  tbody {
    tr {
      &.row-unpaired {
        background: #eee;
      }
      td {
        padding: 15px 8px;
        min-height: 22px;
        vertical-align: top;
        &.condition-section {
          color: #00a0af;
          text-align: left;
          font-size: 14px;
          font-weight: 700;
          line-height: 18px;
          white-space: pre-line;
          width: 20%;
          vertical-align: top;
        }
        &.status-section {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          @media all and (-ms-high-contrast: none) {
            width: 100px;
          }
          .status-icons {
            padding-top: 35px;
            width: 35px;
            height: 35px;
            background-size: 35px;
            background-image: url(${statusIcons.src});
            background-position: 0 36px;
            display: flex;
            justify-content: center;
            &.recruiting {
              background-position: ${statusIcons.recruiting};
            }
            &.completed,
            &.study.complete,
            &.available,
            &.recruitment_complete,
            &.recruitment.complete {
              background-position: ${statusIcons.completed};
            }
            &.terminated {
              background-position: ${statusIcons.terminated};
            }
            &.not {
              background-position: ${statusIcons.active_not_recruiting};
            }
            &.yet {
              background-position: ${statusIcons.no_yet_recruiting};
            }
          }
          .status-text {
            color: #544f40;
            font-size: 14px;
            margin-top: 5px;
            text-align: center;
            @media all and (-ms-high-contrast: none) {
              width: 100%;
            }
          }
        }
        &.requirements-section {
          width: 18%;
          .req-div {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-weight: 700;
            color: #544f40;
            img {
              margin-right: 5px;
            }
            .req-text {
              white-space: pre-line;
              font-family: 'Muli', sans-serif;
            }
          }
        }
        &.distance-section {
          &.align-fix {
            vertical-align: middle !important;
          }
          color: #544f40;
          text-align: left;
          font-size: 12px;
          line-height: 18px;
          padding: 15px;
          strong {
            color: #544f40;
            font-size: 14px;
            font-weight: 700;
            white-space: pre;
            padding-top: 20px;
          }
        }
        &.details-section {
          a {
            background-color: #e31c39;
            border-color: #e31c39;
            color: #ffffff;
            padding: 6px 16px;
            font-size: 14px;
            line-height: 18px;
            font-weight: 400;
            white-space: nowrap;
            position: relative;
            top: 6px;
            :hover {
              background-color: #c11831;
              border-color: #c11831;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  .clampRow {
    .mobile {
      display: none;
    }
  }
  .clamp-title {
    padding-top: 15px;
    padding-bottom: 25px;
  }
  .clamp-lines {
    color: #544f40;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    position: relative;
    padding-right: 20px;
    button {
      border: 0;
      background-color: transparent;
      position: absolute;
      right: -4px;
      top: -4px;
      svg {
        color: #c11831;
        height: 20px;
        width: 15px;
      }
      &:focus {
        outline: none;
      }
    }
  }
  @media screen and (max-width: 991px) {
    .table-container {
      margin: 0;
    }
    /* Pivot the table */
    .responsiveTable table,
    .responsiveTable thead,
    .responsiveTable tbody,
    .responsiveTable th,
    .responsiveTable td,
    .responsiveTable tr {
      display: block;
    }

    .responsiveTable thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    .responsiveTable tbody tr {
      border-top: 1px solid #f5f5f5;
      border-left: 1px solid #f5f5f5;
      border-right: 1px solid #f5f5f5;
      border-bottom: 0;
      padding: 0.25em;
    }

    .responsiveTable td.pivoted {
      /* Behave like a "row" */
      border: none !important;
      position: relative;
      padding-left: calc(50% + 10px) !important;
      text-align: left !important;
      width: auto;
      border-bottom: 1px solid #f5f5f5 !important;
      font-size: 12px;
      &.status-section {
        align-items: flex-start;
        justify-content: flex-start;
        .status-text {
          text-align: left;
          font-size: 12px;
        }
      }
      &.requirements-section {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        .req-div {
          img {
            height: 32px;
          }
        }
      }
      &.details-section {
        display: none;
        border: 0 !important;
        padding-left: 10px !important;
      }
    }

    .responsiveTable td .tdBefore {
      /* Now like a table header */
      position: absolute;
      display: block;
      color: #000;
      font-size: 12px;

      /* Top/left values mimic padding */
      left: 10px;
      width: calc(50% - 20px);
      white-space: nowrap;
      overflow-wrap: break-word;
      text-align: left !important;
      font-weight: 700;
      svg,
      img {
        display: none;
      }
    }
    .clampRow {
      margin-bottom: 30px;
      border-top: 0 !important;
      border-bottom: 1px solid #f5f5f5 !important;
      border-left: 1px solid #f5f5f5;
      border-right: 1px solid #f5f5f5;
      .mobile {
        display: block !important;
        margin-bottom: 8px;
        .tdBefore {
          display: none;
        }
      }
    }
    .clamp-lines {
      font-size: 12px;
    }
    .clamp-title {
      padding-top: 15px;
      padding-bottom: 10px;
    }
  }
`;

export const ResultsPage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  .map-sec {
    margin-bottom: 40px;
    box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.25);
    > div {
      height: 400px;
    }
    .marker-text {
      margin: 5px 0 10px 0;
      strong {
        color: #212529;
        font-weight: 400;
        font-size: 12px;
      }
    }
  }
  .no-results-text {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #212529;
    text-align: left;
    margin-bottom: 40px;
    a {
      color: #e31c39;
      margin: 0 5px;
      :hover {
        color: #0056b3;
        text-decoration: none;
      }
    }
  }
  .row {
    max-width: 1110px;
    width: 100%;
  }
  .tabel-sec {
    margin-top: 30px;
  }
  .pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 8px 0;
    p {
      font-size: 16px;
      color: #544f40;
    }
    a {
      i {
        &.next {
          background: url(/icons/pagination-arrow.png) no-repeat center center;
          background-size: contain;
          height: 20px;
          width: 16px;
          display: block;
        }
        &.prev {
          background: url(/icons/pagination-arrow-l.png) no-repeat center center;
          background-size: contain;
          height: 20px;
          width: 16px;
          display: block;
        }
      }
      :hover {
        i {
          &.next {
            background: url(/icons/pagination-arrow-hover.png) no-repeat center
              center;
            background-size: contain;
          }
          &.prev {
            background: url(/icons/pagination-arrow-hover-l.png) no-repeat
              center center;
            background-size: contain;
          }
        }
      }
    }
  }
  .page-item {
    :focus {
      outline: none;
    }
    &.disabled {
      display: none;
    }
    &.active {
      .page-link {
        color: #fff;
        text-decoration: none;
        background-color: #e31c39;
        border-color: #e31c39;
      }
    }
    .page-link {
      padding-left: 10px;
      padding-right: 10px;
      color: #544f40;
      font-size: 16px;
      &:focus {
        box-shadow: none;
      }
      &:hover {
        color: #fff;
        text-decoration: underline;
        background-color: #e31c39;
        border-color: #e31c39;
      }
    }
  }
  .bottom-pagination {
    display: flex;
    justify-content: flex-end;
    .pagination {
      margin: 16px 0 0 0;
    }
  }
  .filter-sec {
    form {
      button {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`;

export const DetailsPageContainer = styled.div`
  margin-top: 15px;
  padding: 0 15px;
  .hero-section {
    .ipd-wrapper {
      display: flex;
      align-items: center;
      justify-content: start;
      a {
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        background-color: ${pinkFont};
        margin: 0 0 25px 0;
        padding: 6px 16px;
        color: white;
        border: none;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
          border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        &:hover {
          background-color: #c11831;
        }
        &:focus {
          outline: none;
        }
      }
    }
    .hero-line-separator {
      padding: 0 16px;
    }
    .ct-gov-ipd {
      border-right: none;
    }
  }
  @media screen and (min-width: 768px) {
    .ipd-wrapper {
      justify-content: center !important;
      border-left: 2px solid #d5d1ce;
    }
    .ipd-wrapper {
      a {
        margin-top: 25 !important;
      }
    }
    .eudra-ipd {
      margin-top: 25px;
    }
  }
  @media screen and (min-width: 992px) {
    .hero-line-separator {
      display: none;
    }
    .ipd-wrapper {
      a {
        margin-bottom: 0 !important;
      }
    }
    .eudra-ipd {
      margin-top: 0 !important;
    }
    .hero-section {
      .ct-gov-ipd {
        border-right: 2px solid #d5d1ce !important;
      }
    }
  }
  h3 {
    margin-bottom: 20px;
  }
  a,
  button {
    :hover {
      text-decoration: underline;
    }
  }
  .back-to-search {
    background: url(/icons/icon-angle-left.png) no-repeat 0;
    -webkit-appearence: none;
    border: 0;
    background-color: transparent;
    padding: 6px 12px 6px 15px;
    font-size: 16px;
    line-height: 1.5;
    display: block;
    color: #4a4a4a;
    margin-bottom: 6px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    :hover {
      color: ${pinkFont};
      text-decoration: underline;
    }
    :focus {
      outline: none;
    }
  }
  hr {
    border-top: 2px solid #d5d1ce;
    margin: 15px 0;
  }
  .middle-column {
    border-left: 2px solid #d5d1ce;
    border-right: 2px solid #d5d1ce;
  }
  .hero-section {
    div {
      text-align: center;
      p {
        font-size: 18px;
        a {
          font-size: 18px;
          text-decoration: underline;
          &:hover {
            color: #e31c39;
          }
        }
      }
      p:nth-child(3) {
        margin-bottom: 25px;
      }
    }
    > div:first-child {
      text-align: left;
      p,
      strong {
        font-size: 18px;
        line-height: 34px;
      }
      p {
        margin-bottom: 16px;
      }
    }
    svg {
      font-size: 16px;
      color: ${pinkFont};
      position: relative;
      top: -3px;
    }
  }
  .status-card {
    width: 138px;
    height: 138px;
    background-color: ${pinkFont};
    margin-bottom: 15px;
    text-align: center;
    float: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
    strong {
      color: ${white};
      font-size: 18px;
    }
    .status-icon {
      width: 46px;
      height: 46px;
      margin: 0 auto 5px auto;
      background-size: 46px;
      background-image: url(${whiteStatusIcons.src});
      &.completed,
      &.study_complete,
      &.study.complete,
      &.available,
      &.recruitment_complete {
        background-position: ${whiteStatusIcons.completed};
      }
      &.recruiting,
      &.will_be_recruiting {
        background-position: ${whiteStatusIcons.recruiting};
      }
      &.terminated,
      &.withdrawn,
      &.other,
      &.active_not_recruiting {
        background-position: ${whiteStatusIcons.terminated};
      }
      &.not_yet_recruiting,
      &.no_longer_recruiting {
        background-position: ${whiteStatusIcons.not_recruiting};
      }
    }
  }
  .has-results {
    max-width: 55%;
    font-size: 12px;
    line-height: 1.2;
    color: ${fontColor};
    display: flex;
    align-items: center;
    margin: 0 auto;
    text-align: center;
    div {
      margin-right: 5px;
      width: 30%;
      font-size: 16px;
      line-height: 24px;
      display: inline-block;
      background-color: ${pinkFont};
      justify-content: center;
      align-items: center;
      padding: 6px 6px 0;
      padding-top: 6px;
      padding-bottom: 0px;
      img {
        max-width: 50%;
      }
    }
    span {
      width: 64%;
      display: inline-block;
      line-height: 13.2px;
      font-weight: 500;
      font-size: 12px;
      vertical-align: middle;
    }
  }
  h3:nth-child(4) {
    margin-top: 45px;
    margin-bottom: 40px;
  }
  .overview-row {
    div {
      text-align: center;
      .overview-icon {
        height: 50px;
        width: 50px;
        margin: 0 auto 15px auto;
        background-image: url(${studyOverviewIcons.src});
        background-size: 50px;
        &.conditions {
          background-position: ${studyOverviewIcons.medicalCond};
        }
        &.product {
          background-position: ${studyOverviewIcons.product};
        }
        &.collaborators {
          background-position: ${studyOverviewIcons.collaborators};
        }
        &.date {
          background-position: ${studyOverviewIcons.date};
        }
        &.type {
          background-position: ${studyOverviewIcons.type};
        }
        &.phase {
          background-position: ${studyOverviewIcons.phase};
        }
        &.gender {
          background-position: ${studyOverviewIcons.gender};
        }
        &.age {
          background-position: ${studyOverviewIcons.age};
        }
        &.healthyVol {
          background-position: ${studyOverviewIcons.healthyVol};
        }
      }
      h6 {
        font-size: 18px;
        margin-bottom: 5px;
        line-height: 21.6px;
        color: ${blueFont};
      }
      p {
        font-size: 18px;
      }
      p:last-child {
        text-transform: capitalize;
        word-wrap: break-word;
      }
    }
  }
  .documents-section {
    flex-direction: column;
    @media all and (-ms-high-contrast: none) {
      display: block;
    }
    > div {
      display: flex;
      margin-bottom: 10px;
    }
    .documents-additional-text {
      padding: 0.5rem 15px 3rem;
      a {
        color: ${pinkFont};
      }
    }
    .download-btn {
      width: 100%;
      height: 100%;
      font-size: 18px;
      line-height: 1.5;
      color: ${blueFont};
      background-color: #f5f5f5;
      padding: 9px 45px 9px 10px;
      position: relative;
      :hover {
        color: ${white};
        background-color: ${pinkFont};
        img {
          border-left: solid 1px #fff;
        }
      }
    }
    span {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      background-color: #e31c39;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      width: 40px;
      background-color: ${pinkFont};
      border-left: solid 1px ${pinkFont};
      padding: 8px 5px;
    }
    .learn-more {
      p {
        display: inline-block;
      }
      a {
        color: ${pinkFont};
      }
    }
  }
  .map-container {
    margin-bottom: 40px;
    > div {
      height: 400px;
    }
    .marker-text {
      strong {
        color: #212529;
        font-weight: 400;
        font-size: 12px;
        line-height: 1;
      }
    }
  }
  .map-container + div {
    padding-left: 0;
  }
  .locations-section {
    > div:first-child {
      margin-bottom: 20px;
    }
    a {
      :hover {
        text-decoration: underline;
      }
    }
  }
  .locations-section + hr + h3 {
    margin-top: 26px;
  }
  .locations-table {
    max-height: 400px;
    overflow: auto;
    border: solid 1px #d5d1ce;
    margin-bottom: 25px;
    table {
      border: 1px solid #dee2e6;
      border-top: 2px solid ${pinkFont};
    }
    tr {
      td {
        color: ${fontColor};
        font-size: 16px;
        line-height: 1.125;
        border: 1px solid #dee2e6;
      }
      td:first-child {
        padding: 5px 10px;
      }
      td:last-child {
        padding: 5px 10px;
        vertical-align: middle;
        text-transform: uppercase;
      }
    }
    tr:nth-child(odd) {
      background-color: #f5f5f5;
    }
  }
  .study-design-up {
    p {
      margin-bottom: 12px;
      strong {
        display: inline-block;
        min-width: 220px;
      }
    }
    margin-bottom: 18px;
  }
  .outcomes-section,
  .arms-section,
  .keyword-section,
  .criteria-section,
  .enrollment-section,
  .publication-section {
    padding: 0 30px;
  }
  .criteria-print {
    display: none;
    width: 100%;
    border-radius: 4.8px;
    margin: 20px 0 30px 0;
    h5 {
      font-size: 20px;
      line-height: 1.5;
      margin-bottom: 10px;
      color: #e31c39;
      margin-top: 10px;
    }
    pre + h5 {
      margin-top: 8px;
    }
    li {
      padding: 0;
      margin-left: 15px;
      border: 0;
      color: #544f40;
      font-size: 14px;
      line-height: 1.5;
    }
  }
  @media print {
    @page {
      size: auto;
      margin: 15mm 0 15mm 0;
    }
    .criteria-print {
      display: block;
    }
    .locations-table {
      max-height: initial;
    }
    .gm-style div > img {
      position: absolute;
    }
    .map-container {
      max-width: 760px;
      margin: 0 auto 40px;
    }
  }
  .outcomes-section {
    ul {
      margin-bottom: 16px;
      li {
        font-size: 16px;
        line-height: 24px;
        color: #544f40;
        padding-bottom: 20px;
      }
    }
  }

  .left-pad {
    ul {
      padding-left: 40px;
    }
  }

  .arms-section,
  .keyword-section,
  .publication-section {
    ul {
      margin-bottom: 16px;
      li {
        font-size: 16px;
        line-height: 24px;
        color: #544f40;
        font-weight: 700;
      }
    }
  }
  .publication-section {
    ul li {
      font-weight: normal;
    }
  }
  .criteria-section,
  .enrollment-section {
    margin-bottom: 10px;
  }
  .central-contact-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 15px;
    div {
      margin-bottom: 4px;
      line-height: 21px;
      strong {
        margin-bottom: 3px;
        line-height: 19px;
        display: inline-block;
      }
    }
  }

  .back-to-search-bottom {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    background-color: ${pinkFont};
    margin: 14px 8px 36px 0;
    padding: 6px 24px;
    color: white;
    border: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:hover {
      background-color: #c11831;
    }
    &:focus {
      outline: none;
    }
  }

  //TABLET AND MOBILE
  @media (max-width: 992px) {
    .status-container {
      display: flex;
      justify-content: center;
    }
    .map-container + div {
      padding-left: 15px;
      table {
        width: 100%;
      }
    }
  }
  //TABLET
  @media (min-width: 768px) and (max-width: 992px) {
    .overview-row {
      div {
        h6 {
          font-size: 14px;
          line-height: 16.8px;
        }
      }
    }
    .hero-section {
      .middle-column {
        .has-result {
          dispplay: flex;
          justify-content: center;
        }
      }
    }
    .has-results {
      display: flex;
      width: 100%;
      max-width: 100%;
      margin-left: 0;
      justify-content: center;
      div {
        margin-right: 5px;
        width: 30%;
        font-size: 16px;
        line-height: 24px;
        display: inline-block;
        text-align: center !important;
        padding-top: 6px;
        padding-bottom: 0px;
        img {
          max-width: 50%;
        }
      }
      span {
        width: 64%;
        display: inline-block;
        line-height: 13.2px;
        font-weight: 500;
        font-size: 12px;
        vertical-align: middle;
      }
    }
  }

  //MOBILE
  @media (max-width: 767px) {
    .back-to-search-bottom {
      padding: 6px 12px;
    }
    .overview-row:nth-of-type(4) {
      margin-bottom: 30px;
    }
    .middle-column {
      border: none;
    }
    .status-card {
      text-align: center !important;
    }
    .none {
      display: none;
    }
    .has-results {
      display: flex;
      margin-bottom: 20px;
      width: 46%;
      max-width: 100%;
      margin-left: 0;
      justify-content: center;
      text-align: center !important;
      div {
        margin-right: 5px;
        width: 30%;
        height: 34px;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-align: center !important;
        padding-top: 4px;
        padding-bottom: 6px;
        img {
          max-width: 50%;
        }
      }
      span {
        width: calc(100% - 78px);
        margin-left: 5px;
        display: inline-block;
        line-height: 13.2px;
        font-weight: 500;
        font-size: 12px;
        vertical-align: middle;
      }
    }
    .hero-section {
      svg {
        height: 24px;
        margin: 0 4px;
      }
      div {
        text-align: left;
      }
      > div:first-child {
        p,
        strong {
          font-size: 22px;
        }
      }
    }
    h3:nth-child(4) {
      margin-top: 30px;
    }
    .overview-row {
      .col-12 {
        padding-left: 95px;
        padding-top: 5px;
        margin-bottom: 20px;
        text-align: left;
      }
      h6 {
        margin-bottom: 5px;
      }
      div {
        .overview-icon {
          display: block;
          position: absolute;
          left: 15px;
          top: 50%;
          margin: 0;
          margin-top: -25px;
        }
      }
    }
    .study-design-up {
      p {
        strong {
          display: inline-block;
          min-width: unset;
          &::after {
            content: ' ';
            white-space: pre;
          }
        }
      }
    }
  }
`;
export const StyledTooltip = styled(UncontrolledTooltip)`
  .tooltip-inner {
    background-color: ${white};
    color: #000000;
    border: 1px solid #544f40;
    border-radius: 4px;
    max-width: 200px;
    padding: 4px 8px;
    text-align: center;
    font-size: 14px;
    line-height: 1.5;
    z-index: 99;
    overflow: visible;
  }
`;

export const NotFoundPage = styled.div`
  h1 {
    margin: 16px 0 32px 0;
  }
  a {
    color: ${blueFont};
  }
`;

export const SitemapPage = styled.div`
  margin-bottom: 40px;
  h1 {
    margin: 20px 0 20px 0;
  }
  ul {
    margin-left: 35px;
  }
  a:hover {
    color: ${pinkFont};
  }
`;

export const StudyNotFoundContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  min-height: 70vh;
  h1 {
    font-size: 40px;
    line-height: 48px;
    margin: 40px 0 !important;
    text-align: center;
  }
  p {
    font-size: 18px;
    margin-bottom: 16px;
    font-weight: 400;
    text-align: center;
    line-height: 24px;
  }
  a {
    color: ${pinkFont};
    font-size: 18px;
  }
  a:hover {
    text-decoration: underline;
  }
  @media (max-width: 767px) {
    p {
      font-size: 16px;
    }
  }
  @media (max-width: 480px) {
    min-height: 100px;
    h1.main-title {
      font-size: 32px;
    }
  }
`;
