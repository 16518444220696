export const accent = '#f90000';
export const white = '#ffffff';
export const black = '#000000';
export const fontColor = '#544F40';
export const fontColorLight = '#72635D';
export const blueFont = '#00a0af';
export const darkFont = '#212529';
export const pinkFont = '#e31c39';
export const buttonHover = '#c11831';
export const greyBG = '#f5f5f5';
export const headerBorder = '#CED4DA';
export const footerBGGrey = '#F5F5F5';
export const generalBgGrey = '#f9f9f9';
export const iconGrey = '#7F7F7F';

export const statusIcons = {
  src: '/icons/sprite.png',
  completed: '0 -35px',
  recruiting: '0 -70px',
  active_not_recruiting: '0 -210px',
  recruitment_completed: '0 -162px',
  terminated: '0 36px',
  no_yet_recruiting: '0 -104px',
};

export const whiteStatusIcons = {
  src: '/icons/sprite-white.png',
  completed: '0 -44px',
  recruiting: '0 -91px',
  not_recruiting: '0 -137px',
  recruitment_completed: '0 -183px',
  terminated: '0 -274px',
};

export const studyOverviewIcons = {
  src: '/icons/overview-sprite.png',
  medicalCond: '0 -448px',
  product: '0 -398px',
  collaborators: '0 -349px',
  date: '0 -298px',
  type: '0 -248px',
  phase: '0 -198px',
  gender: '0 -148px',
  age: '0 -99px',
  healthyVol: '0 -48px',
};
