import React, { useState, useLayoutEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { ContainerHeader } from './header.css';
import { StaticQuery, graphql, Link, navigate } from 'gatsby';
import { Row, Col, Collapse } from 'reactstrap';
import Img from 'gatsby-image';
import Nav from '../nav/nav';
import { FaBars } from 'react-icons/fa';
import Autocomplete from '../autocomplete/autocomplete';
import { IoMdSearch, IoMdClose } from 'react-icons/io';
import { masterDataContext } from '../../store/masterDataContext';

const Header = ({ selectedLanguage, data }) => {
  const { masterData } = useContext(masterDataContext);
  const [mainHeader, setMainHeader] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const [keySugestion, setKeySugestion] = useState([]);
  const [keyValue, setKeyValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  useLayoutEffect(() => {
    let keySugestion = [];
    if (typeof window !== 'undefined') {
      masterData?.CONDITION?.forEach(element => {
        keySugestion.push(element.DisplayValue);
        if (element.Children) {
          element.Children.forEach(subElement =>
            keySugestion.push(subElement.DisplayValue)
          );
        }
      });
      masterData?.COUNTRY?.forEach(element => {
        keySugestion.push(element.DisplayValue);
      });
      const uniqueSuggestions = keySugestion.filter(
        (suggestion, index, array) => array.indexOf(suggestion) === index
      );
      setKeySugestion(uniqueSuggestions);
    }
    if (typeof window !== 'undefined' && window.innerWidth > 480) {
      window.addEventListener('scroll', () => {
        checkScrollPosition();
      });
    }
    return window.removeEventListener('scroll', () => {
      checkScrollPosition();
    });
  }, []);

  const checkScrollPosition = () => {
    if (
      typeof document !== 'undefined' &&
      document.body &&
      document.body.getBoundingClientRect
    ) {
      const bounds = document.body.getBoundingClientRect();
      if (bounds.height > 1300 && bounds.width > 480 && bounds.top < -10) {
        setMainHeader('header-fixed');
      } else {
        setMainHeader('');
      }
    }
  };
  return (
    <ContainerHeader className={mainHeader}>
      {/* desktop version */}
      <div className={'test'}>
        <Row className={'main-row'}>
          {showSearch ? (
            <div className={'search-input'}>
              <IoMdSearch
                onClick={() => navigate(`/en/studies/?SearchTerm=${keyValue}`)}
                className={'search'}
              />
              <Autocomplete
                placeholder={'Enter a search term...'}
                suggestions={keySugestion}
                changeValue={value => {
                  setKeyValue(value);
                }}
                keyDownCallback={e => {
                  if (e.keyCode === 13) {
                    navigate(`/en/studies/?SearchTerm=${keyValue}`);
                  }
                }}
              />
              <IoMdClose onClick={() => setShowSearch(false)} />
            </div>
          ) : null}
          <Col className={'top-menu-section'} md={12}>
            <Link className={'logo'} to={`/${selectedLanguage}/`}>
              <Img
                fluid={data.headerJson.logo.childImageSharp.fluid}
                alt={'Viiv'}
              />
            </Link>
            <div className={'top-menu-nav'}>
              <Nav
                className={'nav-item'}
                navItems={data.site.siteMetadata.topLinksMenu}
                selectedLanguage={selectedLanguage}
                icon={'/icons/icon-contact.png'}
              />
              <button
                onClick={() => setShowSearch(true)}
                className={'search-item'}
              >
                <div className="search-item-content">
                  <img src={'/icons/icon-search.png'} alt={'search'} />
                  <p>{'Search'}</p>
                </div>
              </button>
              <button
                onClick={() => setIsOpen(!isOpen)}
                className={'mobile-menu'}
              >
                <p>{'Menu'}</p>
                <FaBars />
              </button>
            </div>
          </Col>
          <Col className={'menu-section'} xs={12}>
            <Link
              className={'home'}
              to={`/${selectedLanguage}/`}
              aria-label={'home-icon'}
            >
              <i className={'home-icon'}></i>
            </Link>
            <Nav
              navItems={data.site.siteMetadata.mainMenu}
              selectedLanguage={selectedLanguage}
            />
          </Col>
        </Row>
      </div>
      <Row>
        <Col className={'menu-collapse'} xs={12}>
          <Collapse isOpen={isOpen}>
            <Link className={'home'} to={`/${selectedLanguage}/`}>
              <span className={'d-none'}>{'homeIcon'}</span>
              <i className={'home-icon'}></i>
            </Link>
            <Nav
              navItems={data.site.siteMetadata.mainMenu}
              selectedLanguage={selectedLanguage}
            />
          </Collapse>
        </Col>
      </Row>
    </ContainerHeader>
  );
};

const HeaderWithQuery = props => (
  <StaticQuery
    query={graphql`
      query {
        pageMetadataJson {
          pages {
            menuTitle
            url
            pageLang
          }
        }
        site {
          siteMetadata {
            siteTitle
            mainMenu
            topLinksMenu
            languages {
              defaultLangKey
              langs
              defaultPageUrl
            }
          }
        }
        headerJson {
          logo {
            childImageSharp {
              fluid(maxHeight: 500, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          title
        }
      }
    `}
    render={data => <Header data={data} {...props} />}
  />
);

export default HeaderWithQuery;

Header.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
  selectedLanguage: PropTypes.any.isRequired,
};
